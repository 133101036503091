<template>
    <div>
        <v-layout column v-bind:class="{ 'px-5': isMobile() }">
            <v-card class="pa-5 mb-3 yk-card">
                <div class="mb-4 mt-0">
                    <div class="title mb-3 pt-0" style="color: #4289b0">
                        PAYMENT
                    </div>
                </div>
                <v-tabs
                    v-model="paymentMode"
                    color="#4289b0"
                    grow
                    @change="splitPaymentsChanged"
                >
                    <v-tabs-slider color="#4289b0"></v-tabs-slider>
                    <v-tab key="single">
                        single
                    </v-tab>
                    <v-tab key="schedule">
                        schedule
                    </v-tab>
                </v-tabs>
                <v-text-field
                    class="px-2 mt-3"
                    type="number"
                    color="#4289b0"
                    label="Amount"
                    ref="amount"
                    v-model="amount"
                    :rules="amountRules"
                    @input="totalAmountChanged(amount)"
                    prefix="$"
                ></v-text-field>
                <v-menu
                    v-model="startDatePickerMenu"
                    :close-on-content-click="true"
                    offset-y
                    prepend-icon="mdi-currency-usd"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            class="px-2"
                            v-model="startDate"
                            :label="
                                isScheduleMode() ? 'Start Date' : 'Payment Date'
                            "
                            prepend-icon="mdi-calendar"
                            color="#4289b0"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        @change="startDateChanged"
                        v-model="startDate"
                        :min="date"
                        color="#4289b0"
                        :max="latestDate"
                    ></v-date-picker>
                </v-menu>
                <!-- <v-checkbox
                    class="mb-5 mt-0 px-2"
                    hide-details
                    label="Schedule Payments"
                    color="#4289b0"
                    v-model="splitPayments"
                    @change="splitPaymentsChanged"
                ></v-checkbox> -->
                <v-text-field
                    v-if="isScheduleMode()"
                    class="my-1 ml-1 px-2"
                    type="number"
                    label="HOW MANY PAYMENTS?"
                    v-model="scheduleCount"
                    color="#4289b0"
                    :rules="scheduleCountRules"
                    @input="scheduleCountChanged"
                ></v-text-field>

                <div
                    class="mb-3 darken-1 red--text text--darken-1"
                    v-if="hasLateScheduleDate()"
                >
                    Can't schedule payments later than {{ getLatestDate() }}
                </div>

                <schedule-payment
                    ref="schedules"
                    class="px-2"
                    v-if="isScheduleMode()"
                    :schedules="scheduledPayments"
                    :addSchedule="addSchedule"
                    :removeSchedule="removeSchedule"
                    :schedulesDiscrepancy="schedulesDiscrepancy"
                    :updateAmount="updateAmount"
                    :latestDate="latestDate"
                ></schedule-payment>
            </v-card>
        </v-layout>
        <v-layout column v-bind:class="{ 'px-5': isMobile() }">
            <div v-if="type == 'Credit Card'">
                <v-card class="pa-5 mb-3 yk-card">
                    <v-text-field
                        class="px-2 mb-2"
                        label="Card Number"
                        ref="cardNumber"
                        v-mask="creditCardMask"
                        v-model="cardNumber"
                        color="#4289b0"
                        :rules="cardNumberRules"
                        append-icon="mdi-credit-card"
                    ></v-text-field>
                    <v-layout row class="mb-3 px-2 mx-1">
                        <v-text-field
                            label="Exp. Date"
                            class="mr-2"
                            ref="expDate"
                            v-model="expDate"
                            color="#4289b0"
                            v-mask="expDateMask"
                            :rules="expDateRules"
                            placeholder="MM/YY"
                            style="width: 30%;"
                        ></v-text-field>
                        <v-text-field
                            label="Security Code"
                            ref="securityCode"
                            v-model="securityCode"
                            color="#4289b0"
                            :rules="securityCodeRules"
                            v-mask="securityCodeMask"
                            style="width:  30%;"
                        ></v-text-field>
                        <div style="height: 48px" v-if="!isMobile()">
                            <img
                                src="https://utaw.blob.core.windows.net/images/credit-card-cvc-all.svg"
                                style="width: 77px;"
                            />
                            <img
                                src="https://utaw.blob.core.windows.net/images/credit-card-cvc-amex.svg"
                                style="width: 77px;"
                            />
                        </div>
                    </v-layout>
                </v-card>
                <v-card class="pa-5 yk-card">
                    <v-textarea
                        hide-details
                        class="px-2 pt-2"
                        solo
                        color="#4289b0"
                        rows="2"
                        :label="noteLabel"
                        v-model="notes"
                    ></v-textarea>
                </v-card>
            </div>
            <div v-else>
                <v-text-field
                    class="px-4"
                    label="Name on Bank Account"
                    ref="nameOnAccount"
                    v-model="nameOnAccount"
                    :rules="nameOnAccountRules"
                ></v-text-field>
                <v-layout row class="mb-2 px-4 mx-1">
                    <v-text-field
                        label="Routing Number"
                        ref="routingNumber"
                        :mask="routingNumberMask"
                        v-model="routingNumber"
                        :rules="routingNumberRules"
                    ></v-text-field>
                    <div style="height: 48px">
                        <img
                            src="https://utaw.blob.core.windows.net/images/check-routing.svg"
                            style="width: 96px;"
                        />
                    </div>
                </v-layout>
                <v-layout row class="mb-2 px-4 mx-1">
                    <v-text-field
                        label="Account Number"
                        ref="accountNumber"
                        v-model="accountNumber"
                        :rules="accountNumberRules"
                    ></v-text-field>
                    <div style="height: 48px">
                        <img
                            src="https://utaw.blob.core.windows.net/images/check-account.svg"
                            style="width: 96px;"
                        />
                    </div>
                </v-layout>
                <v-text-field
                    class="px-4"
                    label="Confirm Account Number"
                    ref="confirmAccountNumber"
                    v-model="confirmAccountNumber"
                    :rules="confirmAccountNumberRules"
                ></v-text-field>
            </div>
        </v-layout>

        <v-layout row class="mt-4">
            <v-spacer></v-spacer>
            <v-btn
                @click="continueToConfirm"
                v-bind:class="{ 'mr-4': isMobile() }"
                class="button mb-3"
                >Continue</v-btn
            >
        </v-layout>
    </div>
</template>
<script>
import moment from "moment";
import cardValidator from "card-validator";
import { sum, toISOLocal } from "../utils/helpers.js";
import schedulePayment from "./SchedulePayment.vue";
//import LogRocket from "logrocket";

export default {
    components: {
        schedulePayment,
    },
    props: {
        account: Object,
        validateUserInfo: Function,
        responsive: Boolean,
        noteTitle: String,
    },
    data() {
        return {
            showConfirmView: false,
            paymentTypes: ["Credit Card", "Bank Account"],
            type: "Credit Card",
            cardNumber: "",
            expirationMonth: "",
            expirationYear: "",
            securityCode: "",
            notes: "",
            date: toISOLocal(new Date(moment())).substr(0, 10),
            datePickerMenu: false,
            status: "",
            selectedCard: "",
            nameOnCard: "",
            amount: "",
            processing: false,
            checkNumber: "",
            receipt: "",
            today: toISOLocal(new Date(moment())).substr(0, 10),
            expDateMask: "##/##",
            expDate: "",
            paymentResponse: "",
            latestDate: "",

            startDatePickerMenu: false,
            startDate: toISOLocal(new Date(moment())).substr(0, 10),

            nameOnAccount: "",
            routingNumber: "",
            accountNumber: "",
            confirmAccountNumber: "",

            routingNumberMask: "#########",

            scheduledPayments: [],
            scheduleCount: 2,
            schedulesDiscrepancy: false,
            saveForFuture: true,
            splitPayments: false,

            paymentMode: null,

            amountSelection: "",
            noteLabel: "Note",

            amountRules: [
                (value) => !!value || "Amount Is Required",
                (value) => {
                    return !value || value > 0 || "Amount Is Required";
                },
            ],
            scheduleCountRules: [
                (value) => !this.isScheduleMode() || !!value || "Required",
                (value) => {
                    return !value || value > 1 || "Must be at least 2";
                },
                (value) => {
                    return !value || value < 13 || "Can't be more than 12";
                },
            ],

            nameOnCardRules: [
                (v) => this.type != "Check" || !!v || "Name Is Required",
            ],

            cardNumberRules: [
                (v) => this.selectedCard || !!v || "Card Number Is Required",
                (v) => (v && this.validateCard()) || "Invalid Card Number",
            ],
            expDateRules: [
                (v) => !!v || "Expiration Date Is Required",
                (v) =>
                    (v && this.validateExpDate()) ||
                    "A valid 4 digit expiration date required",
            ],
            expirationMonthRules: [
                (v) =>
                    this.selectedCard || !!v || "Expiration Month Is Required",
                (v) =>
                    (v && this.validateExpirationMonth()) ||
                    "Invalid Expiration Month",
            ],
            expirationYearRules: [
                (v) =>
                    this.selectedCard || !!v || "Expiration Year Is Required",
                (v) =>
                    (v && this.validateExpirationYear()) ||
                    "Invalid Expiration Year",
            ],
            securityCodeRules: [
                // v => this.selectedCard || !!v || "Security Code Is Required",
                (v) =>
                    !v ||
                    (v && this.validateSecurityCode()) ||
                    "Invalid Security Code",
            ],
            nameOnAccountRules: [
                (v) =>
                    this.type == "Credit Card" ||
                    !!v ||
                    "Actual account name is required",
            ],
            routingNumberRules: [
                (v) =>
                    this.type == "Credit Card" ||
                    !!v ||
                    "Routing Number Is Required",
                (v) =>
                    (v && this.validateRoutingNumber()) ||
                    "Routing number is required",
            ],
            accountNumberRules: [
                (v) =>
                    this.type == "Credit Card" ||
                    !!v ||
                    "Account Number Is Required",
            ],
            confirmAccountNumberRules: [
                (v) =>
                    this.type == "Credit Card" ||
                    !!v ||
                    "Account Number Is Required",
                (v) =>
                    (v && this.accountNumber == v) || "Account does not match",
            ],
        };
    },
    computed: {
        formatedDate() {
            return this.date ? moment(new Date(this.date)).format("L") : "";
        },
        creditCardMask() {
            const numberValidation = cardValidator.number(this.cardNumber);

            return numberValidation.card &&
                numberValidation.card.type == "american-express"
                ? "#### ###### #####"
                : "#### #### #### ####";
        },
        securityCodeMask() {
            const numberValidation = cardValidator.number(this.cardNumber);

            return numberValidation.card && numberValidation.card.code.size == 4
                ? "####"
                : "###";
        },
    },
    watch: {
        date() {
            if (!this.scheduledPayments.length) return;

            this.scheduledPayments[0].date = this.date;
        },
        noteTitle(newValue) {
            if (newValue) {
                this.noteLabel = newValue;
            }
        },
    },
    async created() {
        if (this.$store.state.app.paymentFields) {
            this.cardNumber = this.$store.state.app.paymentFields.cardNumber;
            this.amount = this.$store.state.app.paymentFields.amount;
            this.expDate = this.$store.state.app.paymentFields.expDate;
            this.securityCode = this.$store.state.app.paymentFields.securityCode;
            this.scheduleCount = this.$store.state.app.paymentFields.scheduleCount;
            this.paymentMode = this.$store.state.app.paymentFields.paymentMode;
            this.scheduledPayments = this.$store.state.app.paymentFields.scheduledPayments;
            this.notes = this.$store.state.app.paymentFields.notes;
            this.startDate = this.$store.state.app.paymentFields.startDate;
        }
        this.loadLatestScheduleDate();
    },
    methods: {
        async loadLatestScheduleDate() {
            const response = await this.$http.get("getLatestPaymentDate");

            this.latestDate = new Date(response.value)
                .toISOString()
                .substr(0, 10);
        },
        isMobile() {
            return this.responsive;
        },
        getLatestDate() {
            return moment(this.latestDate).format("L");
        },
        onAmountSelected(value) {
            if (value == "other") {
                this.amount == "";
            }

            this.amount = value;
        },
        hasLateScheduleDate() {
            return this.scheduledPayments.some(
                (p) => Date.parse(p.date) > Date.parse(this.latestDate)
            );
        },
        async continueToConfirm() {
            if (![this.validate()].every(Boolean)) return;

            if (this.hasLateScheduleDate()) {
                return;
            }

            this.$store.commit("app/setPayment", this.getPaymentData());
            this.$store.commit("app/setPaymentFields", {
                amount: this.amount,
                cardNumber: this.cardNumber,
                expDate: this.expDate,
                startDate: this.startDate,
                securityCode: this.securityCode,
                scheduleCount: this.scheduleCount,
                scheduledPayments: this.scheduledPayments,
                paymentMode: this.paymentMode,
                notes: this.notes,
                manualPayment: this.manualPayment,
            });

            this.$router.push({
                name: "ConfirmPayment",
            });
        },
        goBack() {
            this.showConfirmView = false;
        },
        mockSchedule() {
            return {
                date: new Date().toISOString().substr(0, 10),
                amount: 0,
                datePickerMenu: false,
            };
        },
        isScheduleMode() {
            return this.paymentMode == 1;
        },
        splitPaymentsChanged() {
            //if (!newValue) return;

            this.generateSchedules(this.scheduleCount, this.amount);
        },
        startDateChanged(newValue) {
            if (!newValue) return;

            this.generateSchedules(this.scheduleCount, this.amount, newValue);
        },
        scheduleCountChanged(value) {
            if (value < 2 || value > 12) return;

            this.generateSchedules(value, this.amount);
        },
        totalAmountChanged(amount) {
            if (this.selectionAmounts && this.selectionAmounts.length) {
                const selectedAmount = this.selectionAmounts.find(
                    (s) => s.amount == +amount
                );

                this.amountSelection = selectedAmount
                    ? selectedAmount.amount
                    : "other";
            }

            if (!this.scheduledPayments.length) return;

            this.generateSchedules(this.scheduleCount, amount);
        },
        generateSchedules(count, totalAmount, startDate = null) {
            const amount = totalAmount / count;

            this.scheduledPayments = [];
            startDate = startDate ? startDate : this.startDate;

            for (var i = 0; i < count; i++) {
                const schedule = this.mockSchedule();

                schedule.amount = +amount.toFixed(2);
                schedule.date = toISOLocal(
                    new Date(moment(startDate).add(i, "months"))
                ).substr(0, 10);

                this.scheduledPayments.push(schedule);
            }
        },
        validateCard() {
            const numberValidation = cardValidator.number(this.cardNumber);

            return numberValidation.isValid;
        },
        validateExpDate() {
            const date = this.expDate.replace("/", "");

            if (date.length > 4) return false;

            return cardValidator.expirationDate(this.expDate).isValid;
        },
        validateExpirationMonth() {
            return cardValidator.expirationMonth(this.expirationMonth).isValid;
        },
        validateExpirationYear() {
            return cardValidator.expirationYear(this.expirationYear).isValid;
        },
        validateSecurityCode() {
            const numberValidation = cardValidator.number(this.cardNumber);

            if (
                numberValidation &&
                numberValidation.card &&
                numberValidation.card.code
            ) {
                return cardValidator.cvv(
                    this.securityCode,
                    numberValidation.card.code.size
                ).isValid;
            }

            return cardValidator.cvv(this.securityCode).isValid;
        },
        updateAmount() {
            this.amount = sum(
                this.scheduledPayments.map((schedule) => +schedule.amount)
            ).toFixed(2);
        },
        validateScheduledPayments() {
            if (!this.isScheduleMode()) return [true];

            return this.$refs.schedules.$refs.amount.map((a) =>
                a.validate(true)
            );
        },
        prefillAmount(amount) {
            this.amount = amount;

            this.totalAmountChanged(amount);
        },
        validate() {
            const validations = [this.$refs.amount.validate(true)];

            validations.push(...this.validateScheduledPayments());

            if (this.type == "Credit Card") {
                validations.push(this.$refs.cardNumber.validate(true));
                validations.push(this.$refs.expDate.validate(true));
                validations.push(this.$refs.securityCode.validate(true));

                return validations.every(Boolean);
            }

            validations.push(this.$refs.nameOnAccount.validate(true));
            validations.push(this.$refs.routingNumber.validate(true));
            validations.push(this.$refs.accountNumber.validate(true));
            validations.push(this.$refs.confirmAccountNumber.validate(true));

            return validations.every(Boolean);
        },
        addSchedule() {
            this.scheduledPayments.push({
                date: new Date().toISOString().substr(0, 10),
                amount: 0,
                datePickerMenu: false,
                checkNumber: "",
            });
        },
        removeSchedule(index) {
            this.scheduledPayments.splice(index, 1);
        },
        isScheduledPayment(schedule) {
            return !this.isToday(schedule.date);
        },
        isToday(date) {
            return moment(date).isSame(new Date(), "day");
        },
        getPaymentType(type) {
            if (type == "Credit Card") return "CC";

            return type;
        },
        getCardNumber() {
            if (this.type == "Credit Card")
                return this.cardNumber.replace(/\s/g, "");

            return this.routingNumber + "|" + this.accountNumber;
        },
        getEmailAddress() {
            if (this.account.emailAddress) return [this.account.emailAddress];

            return ["no-reply@utaw.org"];
        },
        getNotes() {
            return this.notes;
        },
        getPaymentData() {
            const payments = this.scheduledPayments.filter((s) => s.amount > 0);

            const today = moment().format("YYYY-MM-DD HH:mm:ss");

            const currentPaymentAmount = this.isScheduleMode()
                ? sum(
                      payments
                          .filter((s) => {
                              return this.isToday(s.date);
                          })
                          .map((schedule) => +schedule.amount)
                  )
                : this.startDate > today
                ? 0
                : +this.amount;

            return {
                isTestPayment: window.location.origin.startsWith(
                    "http://localhost"
                ),
                accountID: this.account.accountId,
                billingName: this.account.name,
                departmentId: 45,
                paymentCategories:
                    currentPaymentAmount > 0
                        ? [
                              {
                                  categoryCode: "YKC",
                                  amount: currentPaymentAmount,
                              },
                          ]
                        : null,
                paymentDate: today,
                paymentType: this.getPaymentType(this.type),
                saveForFuture: false,
                x_Amount: currentPaymentAmount || null,
                x_exp_date: this.expDate.replace("/", ""),
                x_Card_Num: this.getCardNumber(),
                x_CVV_Code: this.securityCode,
                notes: this.getNotes(),
                appSource: "YK",
                emailAddressArray: this.getEmailAddress(),
                schedule: this.hasSchedules(payments)
                    ? {
                          recurrenceCount: payments.filter(
                              this.isScheduledPayment
                          ).length,
                          recurrenceType: "custom",
                          scheduledTransectionDetails: this.getSchedules(
                              payments
                          ),
                      }
                    : null,
            };
        },
        hasSchedules(payments) {
            return (
                this.startDate > this.today ||
                (this.isScheduleMode() &&
                    payments.filter(this.isScheduledPayment).length)
            );
        },
        getSchedules(payments) {
            if (!this.isScheduleMode()) {
                return [
                    {
                        scheduleDate: moment(this.startDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        amount: +this.amount,
                        paymentCategories: [
                            {
                                categoryCode: "YKC",
                                amount: +this.amount,
                            },
                        ],
                    },
                ];
            }

            return payments.filter(this.isScheduledPayment).map((schedule) => ({
                scheduleDate: moment(schedule.date).format(
                    "YYYY-MM-DD HH:mm:ss"
                ),
                amount: schedule.amount,
                paymentCategories: [
                    {
                        categoryCode: "YKC",
                        amount: +schedule.amount,
                    },
                ],
            }));
        },
        async saveVisit() {
            // try {
            //     await this.$http.post("api/account/saveVisit", {
            //         name: this.manual.name,
            //         phone: this.formatPhone(this.manual.phone),
            //     });
            // } catch (e) {
            //     return;
            // }
        },
    },
};
</script>
<style>
.amount-button {
    width: 110px;
    height: 110px;
    box-shadow: 2px 2px 4px #00000054;
    border-radius: 4px;
    cursor: pointer;
}
.amount-button-mobile {
    width: 90px;
    height: 90px;
    box-shadow: 2px 2px 4px #00000054;
    border-radius: 4px;
    cursor: pointer;
}
.amount-button-text {
    text-align: center;
    font-size: 34px;
}
.amount-button-text-mobile {
    text-align: center;
    font-size: 24px;
}
.duration-amount {
    font: Bold 28px/34px Roboto;
}
.duration-amount-mobile {
    font: Bold 20px/24px Roboto;
}
.button-image {
    width: 67px;
    height: 66px;
}
.button-image-mobile {
    width: 57px;
    height: 56px;
}
.selected-campaign {
    border: yellow 3px solid;
}
</style>
